import React from 'react'
import styles from './characteristicsTable.module.scss'
import { t } from '../../../../common/i18n'
import { BateriasInfo } from '../../type/bateriaInfo'

interface Props {
  bateria: BateriasInfo
}
export const CharacteristicsTable: React.FC<Props> = ({ bateria }) => (
  <dl className={styles.characteristics__table}>
    <dt>{t('baterias.ficha.Marca')}</dt>
    <dd>{bateria.marca}</dd>

    <dt>{t('baterias.ficha.Tensión')}</dt>
    <dd> {bateria.voltaje}</dd>
    <dt>{t('baterias.ficha.amperaje')}</dt>
    <dd> {bateria.amperaje}</dd>
    <dt>{t('baterias.ficha.bornes')}</dt>
    <dd> {bateria.posicion_positivo}</dd>
  </dl>
)
