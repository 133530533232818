import React from 'react'
import { BateriasInfo } from '../../type/bateriaInfo'
import Button from '../../../../common/components/buttons/Button'
import { t } from '../../../../common/i18n'

interface Props {
  bateria: BateriasInfo
  selectProduct: (selected: BateriasInfo) => void
  openModal: (open: boolean) => void
}
const RequestButton: React.FC<Props> = ({
  bateria,
  selectProduct,
  openModal,
}) => {
  const onClickRequest = () => {
    selectProduct(bateria)
    openModal(true)
  }
  return (
    <Button
      disclaimerMessage={t('bateria.ficha.mensaje_no_disponible')}
      buttonClass={'rodi_button_no_color'}
      onClick={onClickRequest}>
      {t('landing_neumaticos.ver_disponibilidad')}
    </Button>
  )
}

export default RequestButton
