import React, { ReactNode } from 'react'
import { IAppState } from '../../../../common/context/context'
import { intl, locale, t } from '../../../../common/i18n'
import * as CestaActions from '../../../../common/context/actions/cestaActions'
import { ProductInfo } from '../../../../common/context/actions/cestaActions'
import { DataSteps, Steps } from '../../../../common/components/cesta/Steps'
import { navigate } from 'gatsby'
import styles from './bateriasFicha.module.scss'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { selectPostCode } from '../../../../common/context/selectors/userSelectors'
import { CharacteristicsTable } from './CharacteristicsTable'
import { BateriasInfo } from '../../type/bateriaInfo'
import { CharacteristicsParagraphs } from './CharacteristicsParagraphs'
import { priceWithTax } from '../../../../common/components/FichasNeumaticos/utils'
import ImageGallery from '../../../../common/components/imageGallery/ImageGallery'

interface BateriaFichaProps {
  postCode: string | null
  bateria: BateriasInfo
  cta: ReactNode
  estaciones?: string[]
}
const BateriasFicha: React.FC<BateriaFichaProps> = ({ bateria, cta: Cta }) => {
  return (
    <div className={styles.bateria_ficha__container}>
      <div className={styles.bateria_ficha__left_sidebar}>
        <ImageGallery
          fotos={[{ url: bateria.urlImagenFicha }]}
          marca={bateria.logo}
        />
      </div>
      <div className={styles.bateria_ficha__content}>
        <div className={styles.bateria_ficha__row}>
          <div
            className={`${styles.bateria_ficha__full_column} ${styles.order_1}`}>
            <h1>
              <span>{bateria[`nombre_producto_${locale}`]}</span>
            </h1>
          </div>
          <div
            className={`${styles.bateria_ficha__left_column} ${styles.order_5}`}>
            <CharacteristicsParagraphs
              bateria={bateria}
              title={'baterias.ficha.info.recomendado_para'}
              textKeys={['recomendado_para']}
            />
            <CharacteristicsParagraphs
              bateria={bateria}
              title={'baterias.ficha.info.caracteristicas'}
              textKeys={[
                'caracteristicas1',
                'caracteristicas2',
                'caracteristicas3',
              ]}
            />
            <h2>{t('baterias.ficha.info.caracteristicas_tecnicas')}</h2>
            <CharacteristicsTable bateria={bateria} />
          </div>
          <div
            className={`${styles.bateria_ficha__right_column} ${styles.order_2}`}>
            {bateria.precio ? (
              <div className={styles.bateria_ficha__price}>
                {intl.formatNumber(priceWithTax(bateria.producto), {
                  style: 'currency',
                  currency: 'EUR',
                })}
              </div>
            ) : null}
            {Cta}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSelectedNeumatico: (productInfo: ProductInfo) => {
      dispatch(CestaActions.addProduct(productInfo, 'database'))
      dispatch(CestaActions.setStep(Steps.TU_SOLICITUD))
      navigate(DataSteps[Steps.TU_SOLICITUD].route)
    },
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    postCode: selectPostCode(state),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BateriasFicha)
