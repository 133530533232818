import { PageProps, graphql } from 'gatsby'
import { useSelectedMenu } from '../../../specific/helpers/customHooks/useSelectedMenu'
import { MenuPrincipal } from '../../../specific/constants/menu'
import Layout from '../../components/Layout'
import React, { useState } from 'react'
import { t } from '../../i18n'
import route from '../../utils/route'
import BateriasFicha from '../../../coche/baterias/components/ficha/bateriasFicha'
import { productoActivo } from '../../components/FichasNeumaticos/utils'
import ComprarButton from '../../../coche/baterias/components/ficha/ComprarButton'
import RequestButton from '../../../coche/baterias/components/ficha/RequestButton'
import { BateriasInfo } from '../../../coche/baterias/type/bateriaInfo'
import styles from './fichas.module.scss'

const FichaBateriaPage = ({
  data: { bateriaInfo, seoData },
}: PageProps<Queries.FichaBateriaPageQuery>) => {
  useSelectedMenu(MenuPrincipal.COCHE)

  const [showModal, setShowModal] = useState(false)
  const [selectedBateria, setSelectedBateria] = useState(
    null as BateriasInfo | null
  )

  const isActive = productoActivo(bateriaInfo.producto)

  const CTAButton = isActive ? (
    <ComprarButton bateriaInfo={bateriaInfo} />
  ) : (
    <RequestButton
      bateria={bateriaInfo}
      openModal={setShowModal}
      selectProduct={setSelectedBateria}></RequestButton>
  )

  return (
    <Layout
      selector={false}
      seoData={seoData}
      breadCrumItems={[
        { display: t('bread_crumb.inicio'), link: route('index') },
        {
          display: t('bread_crumb.coche'),
          link: route('coche.index'),
        },
        {
          display: t('bread_crumb.baterias'),
          link: route('baterias.index'),
        },
      ]}>
      <section className={styles.ficha_neumatico__container}>
        <section className={styles.ficha_neumatico__content}>
          <BateriasFicha bateria={bateriaInfo} cta={CTAButton} />
        </section>
      </section>
    </Layout>
  )
}
export const pageQuery = graphql`
  query FichaBateriaPage($idSiteProductosBaterias: Int!, $url: String!) {
    bateriaInfo: bateria(
      id_site_productos_baterias: { eq: $idSiteProductosBaterias }
    ) {
      ...bateriaInfo
      ...productoBateriaInfo
    }

    seoData: pageInfo(url: { eq: $url }) {
      ...seoinformation
    }
  }
`
export default FichaBateriaPage
