import React from 'react'
import * as CestaActions from '../../../../common/context/actions/cestaActions'
import { ProductInfo } from '../../../../common/context/actions/cestaActions'
import { BateriasInfo } from '../../type/bateriaInfo'
import { productoActivo } from '../../../../common/components/FichasNeumaticos/utils'
import Button from '../../../../common/components/buttons/Button'
import { t } from '../../../../common/i18n'
import { Dispatch } from 'redux'
import { DataSteps, Steps } from '../../../../common/components/cesta/Steps'
import { navigate } from 'gatsby'
import { IAppState } from '../../../../common/context/context'
import { selectPostCode } from '../../../../common/context/selectors/userSelectors'
import { connect } from 'react-redux'

interface Props {
  onSelectedProduct: (productInfo: ProductInfo) => void
  bateriaInfo: BateriasInfo
  buttonClass?: string
}
const ComprarNeumaticoCTA: React.FC<Props> = ({
  onSelectedProduct,
  bateriaInfo,
  buttonClass = 'rodi_full_width',
}) => {
  const { producto } = bateriaInfo
  const activo = productoActivo(producto)
  const productInfo: ProductInfo = {
    id: producto.id_navision,
    type: 'bateria',
  }

  const onClickComprar = () => {
    if (activo) {
      onSelectedProduct(productInfo)
    }
  }
  return (
    <Button
      buttonClass={buttonClass}
      onClick={onClickComprar}
      disabled={!activo}>
      {t('baterias.ficha.elegir')}
    </Button>
  )
}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    onSelectedProduct: (productInfo: ProductInfo) => {
      dispatch(CestaActions.addProduct(productInfo, 'database'))
      dispatch(CestaActions.setStep(Steps.TU_SOLICITUD))
      navigate(DataSteps[Steps.TU_SOLICITUD].route)
    },
  }
}

const mapStateToProps = (state: IAppState) => {
  return {
    postCode: selectPostCode(state),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ComprarNeumaticoCTA)
